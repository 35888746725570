import materiel1 from "./../assets/taxi on.png"
import materiel2 from "./../assets/lumineux.png"
import materiel3 from "./../assets/BC.png"
import materiel6 from "./../assets/BA.gif"
import materiel7 from "./../assets/imprimante.png"
import materiel10 from "./../assets/afficheur.png"
import materiel13 from "./../assets/BL.png"
import materiel20 from "./../assets/batterie.png"
import materiel21 from "./../assets/canbus.png"
import { toast } from 'react-toastify'
import { Role } from "./../helpers/Role"
import moment from "moment"
import history from "./history"

const formatDate = (date, option = 1) => {

    let naissance = new Date(date)
    let jour = naissance.getDate() < 10 ? "0" + naissance.getDate() : naissance.getDate()
    let mois = (naissance.getMonth() + 1) < 10 ? "0" + (naissance.getMonth() + 1) : naissance.getMonth() + 1
    let annee = naissance.getFullYear()

    if (option === 2) {
        return (annee + "-" + mois + "-" + jour)
    }

    return (jour + "/" + mois + "/" + annee)

}

const autoFormatDate = (e, setFieldValue) => {
    let input = e.target

    if (e.charCode < 47 || e.charCode > 57) {
        e.preventDefault()
    }

    var len = input.value.length

    // On laisse l'utilisateur pouvoir mettre des slash à une certaine position 
    if (len !== 1 || len !== 3) {
        if (e.charCode === 47) {
            e.preventDefault()
        }
    }

    // On met automatiquement le slash pour lui
    if (len === 2) {
        input.value += '/'
    }

    // On met automatiquement le slash pour lui
    if (len === 5) {
        input.value += '/'
    }

    setFieldValue(input.name, input.value)

}

const afficheSupplement = (valeur) => {
    switch (valeur) {
        case 1:
            return "A - 4ème Personne"
        case 2:
            return "B - Neige-Verglas"
        case 3:
            return "C - Bagages > 5 kg"
        case 4:
            return "D - Gros Colis (malles, voiture d'enfants, ...)"
        case 5:
            return "E - Animaux (sauf chien d'aveugle)"
        case 6:
            return "F - Gare"
        case 7:
            return "G - Aéroport"
        case 8:
            return "H - Port"
        case 9:
            return "I - Bagage (sans notion de poids)"
        case 10:
            return "J - Par personne (adulte/mineur) supplémentaire à partir de la 5ème personne"
        case 11:
            return "K - Fauteuil roulant manuel (dans le coffre)"
        case 12:
            return "L - Fauteuil roulant électrique (sur véhicule équipé)"
        case 13:
            return "M - Personne Adulte à côté du chauffeur"
        case 14:
            return "N - Par Personne (dont les 4 premières) à partir de la 5ème Personne"
        case 15:
            return "O - Départ Gare/Aéroport entre 22h et 6h"
        case 16:
            return "P - 1ere Valise"
        case 17:
            return "Q - Par valise supplémentaire"
        case 18:
            return "R - Compensation sur prise en charge (uniquement hors CPAM)"
        case 19:
            return "S - Par personne ADULTE supplémentaire à partir de la 5ème personne"
        case 30:
            return "S00 - Réservation (avance ou immédiate) Sans distinction de ville de stationnement"
        case 31:
            return "S01 - Réservation à l'avance Sans distinction de ville de stationnement"
        case 32:
            return "S02 - Réservation immédiate Sans distinction de ville de stationnement"
        case 33:
            return "S03 - Réservation avec prise en charge dans la zone de stationnement"
        case 34:
            return "S04 - Réservation avec prise en charge en dehors de la zone de stationnement"
        case 40:
            return "S10 - Réservation (avance ou immédiate) ville de stationnement spécifique"
        case 41:
            return "S11 - Réservation à l'avance ville de stationnement spécifique"
        case 42:
            return "S12 - Réservation immédiate ville de stationnement spécifique"
        case 20:
            return "Z - Autre"
        default:
            return "Sans Objet"
    }
}

const afficheCouleur = (valeur) => {
    switch (valeur) {
        case 1:
            return {
                nom: "bleu",
                color: "#0000FF"
            }
        case 2:
            return {
                nom: "vert",
                color: "#00FF00"
            }
        case 4:
            return {
                nom: "rouge",
                color: "#E02626"
            }
        default:
            break;
    }
}

function hexToRGB(h) {
    let r = 0, g = 0, b = 0;

    // 3 digits
    if (h.length === 4) {
        r = "0x" + h[1] + h[1];
        g = "0x" + h[2] + h[2];
        b = "0x" + h[3] + h[3];

        // 6 digits
    } else if (h.length === 7) {
        r = "0x" + h[1] + h[2];
        g = "0x" + h[3] + h[4];
        b = "0x" + h[5] + h[6];
    }

    return {
        r: + +r,
        g: + +g,
        b: + +b
    }
}

const openPDF = (event, afile) => {

    if (!isNullOrUndefined(afile)) {
        var byteCharacters = atob(afile.fichier_contenu);
        var byteNumbers = new Array(byteCharacters.length)
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers)
        var file = new Blob([byteArray], { type: 'application/' + afile.extension_fichier.split[1] + ';base64' })
        var fileURL = URL.createObjectURL(file)

        var element = document.createElement('a')
        element.setAttribute('href', fileURL)
        element.setAttribute('download', afile.fichier_original)
        document.body.appendChild(element)
        element.click()
        setTimeout(function () {
            // Necessaire pour Firefox
            window.URL.revokeObjectURL(fileURL)
        }, 100)
        document.body.removeChild(element)
    }

}

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

const isObjectEmpty = (object) => {
    return Object.keys(object).length === 0
}

/**
 * 
 * @param {number} value 
 */
const getNomLiaisonTax = (value) => {
    switch (value) {
        case 1:
            return "Capt-fil / Taximètre"
        case 2:
            return "Capt-fil / Boit. Raccord"
        case 3:
            return "Lum. / Boîte Eau / Boîtier"
        case 4:
            return "Taximètre / Boîtier"
        case 5:
            return "Batterie / Boîtier"
        case 6:
            return "Lumineux / Boîtier"
        case 7:
            return "Lumineux / Taximètre"
        case 8:
            return "???????????????????"
        case 9:
            return "Lumineux / Boîte Eau / Tax"
        case 10:
            return "Afficheur / Taximètre"
        case 11:
            return "Imprimante / Taximètre"
        case 12:
            return "Lumineux / Boitier Lum"
        case 13:
            return "Taximètre / Batterie"
        case 14:
            return "Boitier Lumineux / Lum"
        case 15:
            return "Boitier Lumineux / Tax"
        case 16:
            return "Boitier Lum. / Boit. Raccord"
        case 17:
            return "Imprim. / Boit. Raccord"
        case 18:
            return "Imprimante / Batterie"
        case 19:
            return "Boîtier lumineux / Batterie"
        case 20:
            return "Boit.lumineux / Boite eau / Lum"
        default:
            return "Inconnu"
    }
}

/**
 * 
 * @param {number} valeur 
 */
const getNomMateriel = (valeur) => {
    switch (valeur) {
        case 1:
            return "Taximètre"
        case 2:
            return "Lumineux"
        case 3:
            return "Boîtier"
        case 4:
            return "Générateur"
        case 6:
            return "Boîte à eau"
        case 7:
            return "Imprimante"
        case 9:
            return "Prise d'impulsion"
        case 10:
            return "Afficheur"
        case 11:
            return "Boîtier Recopie"
        case 12:
            return "Carte d'extension"
        case 13:
            return "Boîtier Lumineux"
        case 14:
            return "Lecteur Carte Vitale"
        case 20:
            return "Batterie"
        case 21:
            return "Capteur/fil"
        default:
            return "Inconnu"
    }
}

/**
 * 
 * @param {number} valeur Numéro du matériel
 */
const getImageMateriel = (valeur) => {
    switch (valeur) {
        case 1:
            return materiel1
        case 2:
            return materiel2
        case 3:
            return materiel3
        case 6:
            return materiel6
        case 7:
            return materiel7
        case 10:
            return materiel10
        case 13:
            return materiel13
        case 20:
            return materiel20
        case 21:
            return materiel21
        default:
            return undefined
    }
}

/**
 * 
 * @param {number} valeur Valeur du type de liaisons
 * @returns array
 */
const getListImageMateriel = (valeur) => {
    switch (valeur) {
        case 1:
            return [materiel21, materiel1]
        case 2:
            return [materiel21, materiel3]
        case 3:
            return [materiel2, materiel6, materiel3]
        case 4:
            return [materiel1, materiel3]
        case 5:
            return [materiel20, materiel3]
        case 6:
            return [materiel2, materiel3]
        case 7:
            return [materiel2, materiel1]
        case 9:
            return [materiel2, materiel6, materiel1]
        case 10:
            return [materiel10, materiel1]
        case 11:
            return [materiel7, materiel1]
        case 12:
            return [materiel2, materiel13]
        case 13:
            return [materiel1, materiel20]
        case 14:
            return [materiel13, materiel2]
        case 15:
            return [materiel13, materiel1]
        case 16:
            return [materiel13, materiel1]
        case 17:
            return [materiel7, materiel3]
        case 18:
            return [materiel7, materiel20]
        case 19:
            return [materiel13, materiel20]
        case 20:
            return [materiel13, materiel6, materiel2]
        default:
            return undefined
    }
}

/**
 * 
 * @param {string} valeur 
 */
const extractNumberFromString = (valeur) => {
    return valeur.match(/\d+/)[0] ? parseInt(valeur.match(/\d+/)[0]) : undefined
}

const defaultTaximetre = {
    numero_adherent: 0,
    numero_client: 0,
    clef_stationnement: 0,
    clef_lien: 0,
    numero_intervention: 0,
    numero_intervenant: 0,
    c_carnet1: 0,
    c_carnet2: 0,
    c_r99: false,
    r99: "",
    c_r10: false,
    r10: "",
    c_r19: false,
    r19: "",
    c_indication1: 0,
    c_indication2: 0,
    c_indication3: 0,
    c_indication4: 0,
    c_mention1: 0,
    c_mention2: 0,
    c_mention3: 0,
    c_affichagea: 0,
    c_affichageb: 0,
    c_affichagec: 0,
    c_affichaged: 0,
    c_affichagelibre: 0,
    c_affichagelumineux: 0,
    c_etiquettek1: 0,
    etiquettek: 0,
    c_etiquettek2: 0,
    c_etiquettek3: 0,
    c_scelles: 0,
    c_r20: false,
    r20: "",
    c_r21: false,
    r21: "",
    c_r22: false,
    r22: "",
    c_liaisons1: 0,
    c_liaisons2: 0,
    c_liaisons3: 0,
    c_liaisons4: 0,
    c_liaisons5: 0,
    c_liaisons6: 0,
    liaison7: null,
    c_liaisons7: 0,
    liaison8: "",
    c_liaisons8: 0,
    c_liaisons9: 0,
    c_r30: false,
    r30: "",
    c_r31: false,
    r31: "",
    c_r35: false,
    r35: "",
    c_r38: false,
    r38: "",
    c_a09: false,
    c_r40: false,
    r40: "",
    c_r41: false,
    c_r42: false,
    c_r45: false,
    c_r46: false,
    c_r47: false,
    couleur_lumineux: 7,
    c_lumineux_amovible: false,
    c_adaptateur: 0,
    clef_arrete_national: 0,
    clef_dept: 0,
    c_correspondance: 0,
    c_conformite_lettre: 0,
    c_logiciel: 0,
    logiciel: "",
    position_kilo: 0,
    chutes_horaire: 0,
    lue_secondes: 0.0,
    tarif: "",
    commentaires: "",
    c_r71: false,
    c_r81: false,
    c_marque_ce: 0,
    c_inter_1: 0,
    c_inter_2: 0,
    c_liaisons10: 0,
    fixation: 0,
    detail_fixation: "",
    c_liaisons11: 0,
    c_liaisons12: 0,
    c_blinde1: 0,
    c_blinde2: 0,
    c_blinde3: 0,
    c_blinde4: 0,
    c_blinde5: 0,
    c_blinde6: 0,
    c_blinde7: 0,
    c_blinde8: 0,
    c_blinde9: 0,
    c_blinde10: 0,
    c_blinde11: 0,
    c_blinde12: 0,
    c_liaisons13: 0,
    c_blinde13: 0,
    c_liaisons14: 0,
    c_blinde14: 0,
    c_liaisons15: 0,
    c_blinde15: 0,
    c_liaisons16: 0,
    c_blinde16: 0,
    positionnement: 0,
    c_r48: false,
    dam_cet: "",
    c_kit_rehausse: false,
    sans_premiere_chute: false,
    c_cet: 0,
    anomalies: "",
    c_liaisons17: 0,
    c_autoblocage: 0,
    c_liaisons18: 0,
    c_blinde17: 0,
    c_blinde18: 0,
    c_barre_toit: false,
    logiciel_afficheur: "",
    detail_fixation_afficheur: "",
    temporisation: 0,
    c_ampoules: 0,
    c_r91: 0,
    c_elements_accessibles: 0,
    c_lum_non_deplacable: 0,
    c_lum_taxi_visible: 0,
    c_lum_commune_lisible: 0,
    c_lum_commune_taille: 0,
    c_lum_non_cache: 0,
    c_lum_couleur_autorise: 0,
    c_marque_reparateur: 0,
    detail_acces_centrale: "",
    c_taximetre_mid_neuf: 0,
    c_taximetre_mid_notice: 0,
    c_taximetre_mid_attestation: 0,
    c_liaisons19: 0,
    c_blinde19: 0,
    c_ANO_maj_ml_sans_vprim: 0,
    anO_maj_ml_sans_vprim_detail: "",
    c_REF_maj_ml_sans_vprim: 0,
    reF_maj_ml_sans_vprim_detail: "",
    c_ANO_afficheur_depareille: 0,
    anO_afficheur_depareille_detail: "",
    c_REF_afficheur_depareille: 0,
    reF_afficheur_depareille_detail: "",
    c_liaisons20: 0,
    c_blinde20: 0,
    c_toit_ouvrant: false,
}

const isNullOrUndefined = (variable) => {
    if (typeof variable === 'undefined' || variable === null) {
        // La variable est null ou undefined
        return true
    }
    else {
        return false
    }
}

/**
 * 
 * @param {Number} type 
 * @param {String} message 
 * @returns void
 */
const showVignetteStatus = (type, message) => {
    switch (type) {
        case 1:
            toast.error(message, { containerId: 'App' })
            break
        case 2:
            toast.success(message, { containerId: 'App' })
            break
        case 3:
            toast.info(message, { containerId: 'App' })
            break
        case 4:
            toast.warning(message, { containerId: 'App' })
            break
        case 5:
            toast.error(message, { containerId: 'App' })
            toast.success("Vignette unique et non posée, RAS", { containerId: 'App' })
            break
        default:
            return
    }
}

const getDegatVignette = (degat) => {
    let text = " "
    switch (degat) {
        case 0:
            text = "Sans Objet"
            break
        case 1:
            text = "Détruite"
            break
        case 2:
            text = "Perdue"
            break
        case 3:
            text = "Volée"
            break
        case 4:
            text = "Non Conforme"
            break
        case 5:
            text = "Contrôle Destructif"
            break
        case 6:
            text = "Renvoi au Siège"
            break
        case 8:
            text = "Réception par Siège du Retour"
            break
        case 9:
            text = "Remise Stock Siège (remise à 0)"
            break
        default:
            text = "////"

    }
    text += ", elle ne peut avoir été posée. L'intervention sera enregistrée mais des recherches devront être faites."
    return text
}

/**
 * Evite le scroll du body
 * à l'ouverture d'un popup
 */
const lockBody = () => {
    document.body.style.overflow = "hidden"
}

/**
 * Enleve le blocage du scroll du body
 * à la fermeture d'un popup
 */
const unlockBody = () => {
    document.body.style = undefined
}

/**
 * Vérifie si le role
 * permet de voir la page
 */
const canSeePage = (role) => {
    if (isNaN(role)) {
        process.env.NODE_ENV === "development" && console.log(isNaN(role))
        return null
    }
}

/**
 * Vérifie si le role est siege
 * @param {number} role 
 * @returns boolean
 */
const isSiege = (role) => {
    if (typeof role !== "number") {
        role = parseInt(role)
    }
    if (role !== Role.Admin && role !== Role.Siege) {
        return false
    }
    return true
}

const useParisDate = (date) => {
    return (new Date(date)).toLocaleString("en-US", { timeZone: 'Europe/Paris' })
}

/**
 * Check if a value is not null and is an instance of Date
 * @param {*} value 
 */
const isCorrectDate = (value) => {
    return !isNullOrUndefined(value) && moment.isDate(new Date(value))
}

/**
 * Check if a radio button is valid
 * @param {Number} value 
 * @returns Boolean
 */
const isRadioButtonValid = (value) => {
    if (isNullOrUndefined(value)) {
        return false
    }

    value = parseInt(value)

    if (value !== 0) {
        return true
    }
    return false
}

/**
 * @param {Date} date 
 * @returns Boolean
 */
const isValidDate = (date) => {
    var timestamp = Date.parse(date) // retourne NaN si date n'est pas parsable ex 'test'
    if (isNaN(timestamp) === false) {
        return true
    }
    else {
        return false
    }
}

/**
 * @param {String} route 
 * @returns void
 */
const redirectSaisie = (route) => {
    history.push("/intervention/" + route)
}

/**
 * Retourne les détails d'une intervention
 * @param {Intervention} data 
 * @returns String
 */
const getTextInterventionDetail = (data) => {
    let text = ""
    if (data.a1 === true) { text += "A1 : Installation : Montage / " }
    if (data.a2 === true) { text += "A2 : Installation: Démontage/remontage / " }
    if (data.a3 === true) { text += "A3 : Installation : Repose Taximètre réparé / " }
    if (data.a4 === true) { text += "A4 : Installation : Pose de Relais / " }
    if (data.a5 === true) { text += "A5a : Installation : Remise en conformité suite à vignette rouge / " }
    if (data.a5_bis === true) { text += "A5b : Installation : Remise en conformité suite à VP refusée (sans pose vignette rouge) / " }
    if (data.b1 === true) { text += "B1 : VP suite vignette rouge DREETS / " }
    if (data.b2 === true) { text += "B2 : VP annuelle / " }
    if (data.b3 === true) { text += "B3 : VP suite intervention panne / " }
    if (data.b4 === true) { text += "B3 : VP suite Changement de Tarif / " }
    if (data.c1 === true || data.c2 === true) {
        text += "C1 : Bris de scellement sans dépose Taximètre / "
        if (data.detail_c !== "") { text += " : " + data.c_detail }
    }
    if (data.d1 === true) { text += "D1 : Changement Tarif / " }
    if (data.d2 === true) { text += "D2 : Autre : " + data.d2_detail + " / " }
    if (text !== "") { text = text.substring(0, text.length - 3) }

    return text
}

const getArbreInterventionDetail = (data) => {
    let text = ""
    let image = 5
    if (data.d1 === true) {
        text += "D1 : Tarif"
        image = 12
    }
    if (data.d2 === true) {
        text += ((text !== "" ? " / " : "") + "D : divers")
    }
    if (data.c1 === true || data.c2 === true) {
        text += ((text !== "" ? " / " : "") + "C : Bris Scellts")
        image = 5
    }
    if (data.b1 === true || data.b2 === true || data.b3 === true || data.b4 === true) {
        text += ((text !== "" ? " / " : "") + "B : VP")
        image = 13
    }
    if (data.a1 === true || data.a2 === true || data.a4 === true || data.a5 === true || data.a5_bis === true) {
        text += ((text !== "" ? " / " : "") + "A : Montage")
        image = 11
    }
    // VP + tarif
    if ((data.b1 === true || data.b2 === true || data.b3 === true || data.b4 === true) && data.d1 === true && data.c1 === false) {
        image = 18
    }
    // VP + bris
    if ((data.b1 === true || data.b2 === true || data.b3 === true || data.b4 === true) && data.d1 === false && data.c1 === true) {
        image = 17
    }
    // Bris + Tarif
    if ((data.b1 === false || data.b2 === false || data.b3 === false || data.b4 === true) && data.d1 === true && data.c1 === true) {
        image = 19
    }
    // VP + bris + Tarif
    if ((data.b1 === true || data.b2 === true || data.b3 === true || data.b4 === true) && data.d1 === true && data.c1 === true) {
        image = 16
    }

    return {
        text,
        image
    }
}

const getArbreInterventionTachyDetail = (data) => {
    let text = ""
    let image = 5
    if (data.d1 === true) {
        text += "D1 : Dépose"
        image = 12
    }
    if (data.d2 === true) {
        text += ((text !== "" ? " / " : "") + "D : divers")
    }
    if (data.c1 === true) {
        text += ((text !== "" ? " / " : "") + "C : E1")
        image = 5
    }
    if (data.b1 === true) {
        text += ((text !== "" ? " / " : "") + "B : IP")
        image = 13
    }
    if (data.b2 === true || data.b3 === true || data.b4 === true || data.b5 === true || data.b6 === true) {
        text += ((text !== "" ? " / " : "") + "B : IPAI")
        image = 11
    }
    if (data.a1 === true || data.a2 === true || data.a3 === true) {
        text += ((text !== "" ? " / " : "") + "A : Installation")
        image = 16
    }

    return {
        text,
        image
    }
}

/**
 * Retourne les différents types d'une intervention
 */
const interventionDetailArray = [
    "Installation : Montage",
    "Installation: Démontage/remontage",
    "Installation : Repose Taximètre réparé",
    "Installation : Pose de Relais",
    "Installation : Remise en conformité suite à vignette rouge",
    "Installation : Remise en conformité suite à VP refusée (sans pose vignette rouge)",
    "VP suite vignette rouge DREETS",
    "VP annuelle",
    "VP suite intervention panne",
    "VP suite Changement de Tarif",
    "Bris de scellement sans dépose Taximètre",
    "Changement Tarif",
    "Autre",
]

/**
 * Retourne les différents types d'une intervention
 */
const triArray = [
    "Date plus récente",
    "Date plus ancienne",
]

/**
 * Retourne une chaine en conservant les sauts de lignes
 * @param {String} text 
 */
const saveLineBreaks = (text) => {
    return text.replace(/\n/g, '\r\n')
}

/**
 * 
 * @param {Object} historique 
 * @param {String} numero_serie_afficheur 
 * @returns 
 */
const renderHistoriqueAfficheur = (historique, numero_serie_afficheur) => {
    if (historique.serie_appareil === numero_serie_afficheur || (parseInt(historique.serie_appareil) !== 0 && parseInt(numero_serie_afficheur) !== 0 && parseInt(historique.serie_appareil) === parseInt(numero_serie_afficheur))) {
        return "OK"
    }
    else if (historique.serie_appareil === "") {
        return "Pas d'info"
    }
    else {
        if (new Date(historique.date_effectuee) < new Date("01/06/2021")) {
            return "A120"
        }
        else {
            return "R120"
        }
    }
}

/**
 * Retourne le texte correspondant
 * @param {Number} index 
 */
const getImprimanteLabel = (index) => {
    let text = ""
    switch (index) {
        case 0:
            text = "Date de Rédaction de la note"
            break;
        case 1:
            text = "Heures Début et Fin de course"
            break;
        case 2:
            text = "Prestataire/société"
            break;
        case 3:
            text = "Adresse Réclamation (voir ci-dessous)"
            break;
        case 4:
            text = "Montant course minimum"
            break;
        case 5:
            text = "Px Course TTC (hors suppléments)"
            break;
        case 6:
            text = "Somme Totale TTC (avec suppléments)"
            break;
        case 7:
            text = "Détail chaque majoration"
            break;
        case 8:
            text = "Nom du Client"
            break;
        case 9:
            text = "Lieux départ et arrivée"
            break;
        case 10:
            text = "Immatriculation"
            break;
        default:
            break;
    }
    return text
}

const getSaisieTitleName = (pathname) => {
    switch (pathname) {
        case 'reception-vehicule':
            return 'Réception Véhicule'
        case 'reception-pneumatique':
            return 'Réception Pneumatiques'
        case 'etalonnage':
            return 'Etalonnage'
        case 'installateur':
            return 'Installateur'
        case 'taximetre':
            return 'Taximètre'
        case 'autres':
            return 'Autres'
        case 'lumineux':
            return 'Lumineux'
        case 'liaisons':
            return 'Liaisons'
        case 'metrologie':
            return 'Métrologie'
        case 'imprimante':
            return 'Imprimante'
        case 'photos':
            return 'Photos'
        case 'sanction':
            return 'Sanctions'
        case 'infos':
            return 'Infos'
        default:
            break;
    }
}

const loggerDev = (result) => {
    process.env.NODE_ENV === 'development' && console.log(result)
}

const logger = (result) => {
    console.log(result)
}

export {
    formatDate,
    autoFormatDate,
    isObjectEmpty,
    afficheSupplement,
    afficheCouleur,
    hexToRGB,
    openPDF,
    capitalizeFirstLetter,
    getNomLiaisonTax,
    getNomMateriel,
    getImageMateriel,
    extractNumberFromString,
    getListImageMateriel,
    defaultTaximetre,
    isNullOrUndefined,
    showVignetteStatus,
    getDegatVignette,
    lockBody,
    unlockBody,
    canSeePage,
    isSiege,
    useParisDate,
    isCorrectDate,
    isRadioButtonValid,
    isValidDate,
    getTextInterventionDetail,
    getArbreInterventionDetail,
    getArbreInterventionTachyDetail,
    redirectSaisie,
    saveLineBreaks,
    renderHistoriqueAfficheur,
    getImprimanteLabel,
    interventionDetailArray,
    getSaisieTitleName,
    triArray,
    loggerDev,
    logger,
}