import React, { useEffect, useState } from 'react'
import { Formik, Field, Form, useFormikContext } from 'formik'
import { ReactComponent as OK } from './../../../../res/checked.svg'
import { ReactComponent as Erreur } from './../../../../res/siren.svg'
import { ReactComponent as SO } from './../../../../res/erreur.svg'
import { ReactComponent as Intervention } from './../../../../res/alerte.svg'

const Affichage = (props) => {

    const [affichage] = useState(JSON.parse(localStorage.getItem("taximetre")))

    return (
        <div className="tabpanel-content">
            <Formik
                enableReinitialize={true}
                initialValues={{
                    c_affichagea: affichage.c_affichagea,
                    c_affichageb: affichage.c_affichageb,
                    c_affichagec: affichage.c_affichagec,
                    c_affichaged: affichage.c_affichaged,
                    c_affichagelibre: affichage.c_affichagelibre,
                    c_affichagelumineux: affichage.c_affichagelumineux,
                    c_mention1: affichage.c_mention1,
                    c_mention2: affichage.c_mention2,
                    c_mention3: affichage.c_mention3,
                }}
                validate={(values) => {
                    const errors = {}
                    let taximetre = JSON.parse(localStorage.taximetre)
                    // Mise à jour des taximetres
                    taximetre.c_affichagea = values.c_affichagea
                    taximetre.c_affichageb = values.c_affichageb
                    taximetre.c_affichagec = values.c_affichagec
                    taximetre.c_affichaged = values.c_affichaged
                    taximetre.c_affichagelibre = values.c_affichagelibre
                    taximetre.c_affichagelumineux = values.c_affichagelumineux
                    taximetre.c_mention1 = values.c_mention1
                    taximetre.c_mention2 = values.c_mention2
                    taximetre.c_mention3 = values.c_mention3
                    // Partage des données
                    props.passFormToParent(taximetre, errors)
                    // Mise à jour de localStorage
                    localStorage.setItem("taximetre", JSON.stringify(taximetre))
                }}
                onSubmit={async (values) => {
                    //await new Promise((r) => setTimeout(r, 500))
                    //alert(JSON.stringify(values, null, 2))
                }}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <div className="affichage-form-container">
                            {/* 'Tarif A' */}
                            <div role="group" aria-labelledby="group1" className="affichage-item">
                                <div className="affichage-item-title-container">
                                    <div className="affichage-item-title">
                                        Tarif 'A'
                                    </div>
                                </div>
                                <div className="affichage-item-input-container">
                                    <div id="filter1" className="input-items-container">
                                        <div className="input-item">
                                            <Field
                                                type="radio"
                                                name="c_affichagea"
                                                id="filter1-1"
                                                className="sr-only"
                                                onChange={() => setFieldValue('c_affichagea', 1)}
                                                checked={values.c_affichagea === 1 ? true : false} />
                                            <label htmlFor="filter1-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                <OK />
                                                OK
                                            </label>
                                        </div>
                                        {props.interventionInfoGeneral.que_vp === false &&
                                            <div className="input-item">
                                                <Field
                                                    type="radio"
                                                    name="c_affichagea"
                                                    id="filter1-4"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_affichagea', 3)}
                                                    checked={values.c_affichagea === 3 ? true : false} />
                                                <label htmlFor="filter1-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Intervention />
                                                    I13
                                                </label>
                                            </div>
                                        }
                                        <div className="input-item">
                                            <Field
                                                type="radio"
                                                name="c_affichagea"
                                                id="filter1-2"
                                                className="sr-only"
                                                onChange={() => setFieldValue('c_affichagea', 2)}
                                                checked={values.c_affichagea === 2 ? true : false} />
                                            <label htmlFor="filter1-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                <Erreur />
                                                R13
                                            </label>
                                        </div>
                                        <div className="input-item">
                                            <Field
                                                type="radio"
                                                name="c_affichagea"
                                                id="filter1-3"
                                                className="sr-only"
                                                onChange={() => setFieldValue('c_affichagea', 0)}
                                                checked={(values.c_affichagea === 0 || values.c_affichagea === 4) ? true : false} />
                                            <label htmlFor="filter1-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                <SO />
                                                SO
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* 'Tarif B' */}
                            <div role="group" aria-labelledby="group2" className="affichage-item">
                                <div className="affichage-item-title-container">
                                    <div className="affichage-item-title">
                                        Tarif 'B'
                                    </div>
                                </div>
                                <div className="affichage-item-input-container">
                                    <div id="filter1" className="input-items-container">
                                        <div className="input-item">
                                            <Field
                                                type="radio"
                                                name="c_affichageb"
                                                id="filter2-1"
                                                className="sr-only"
                                                onChange={() => setFieldValue('c_affichageb', 1)}
                                                checked={values.c_affichageb === 1 ? true : false} />
                                            <label htmlFor="filter2-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                <OK />
                                                OK
                                            </label>
                                        </div>
                                        {props.interventionInfoGeneral.que_vp === false &&
                                            <div className="input-item">
                                                <Field
                                                    type="radio"
                                                    name="c_affichageb"
                                                    id="filter2-4"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_affichageb', 3)}
                                                    checked={values.c_affichageb === 3 ? true : false} />
                                                <label htmlFor="filter2-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Intervention />
                                                    I13
                                                </label>
                                            </div>
                                        }
                                        <div className="input-item">
                                            <Field
                                                type="radio"
                                                name="c_affichageb"
                                                id="filter2-2"
                                                className="sr-only"
                                                onChange={() => setFieldValue('c_affichageb', 2)}
                                                checked={values.c_affichageb === 2 ? true : false} />
                                            <label htmlFor="filter2-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                <Erreur />
                                                R13
                                            </label>
                                        </div>
                                        <div className="input-item">
                                            <Field
                                                type="radio"
                                                name="c_affichageb"
                                                id="filter2-3"
                                                className="sr-only"
                                                onChange={() => setFieldValue('c_affichageb', 0)}
                                                checked={(values.c_affichageb === 0 || values.c_affichageb === 4) ? true : false} />
                                            <label htmlFor="filter2-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                <SO />
                                                SO
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* 'Tarif C'*/}
                            <div role="group" aria-labelledby="group3" className="affichage-item">
                                <div className="affichage-item-title-container">
                                    <div className="affichage-item-title">
                                        Tarif 'C'
                                    </div>
                                </div>
                                <div className="affichage-item-input-container">
                                    <div id="filter1" className="input-items-container">
                                        <div className="input-item">
                                            <Field
                                                type="radio"
                                                name="c_affichagec"
                                                id="filter3-1"
                                                className="sr-only"
                                                onChange={() => setFieldValue('c_affichagec', 1)}
                                                checked={values.c_affichagec === 1 ? true : false} />
                                            <label htmlFor="filter3-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                <OK />
                                                OK
                                            </label>
                                        </div>
                                        {props.interventionInfoGeneral.que_vp === false &&
                                            <div className="input-item">
                                                <Field
                                                    type="radio"
                                                    name="c_affichagec"
                                                    id="filter3-4"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_affichagec', 3)}
                                                    checked={values.c_affichagec === 3 ? true : false} />
                                                <label htmlFor="filter3-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Intervention />
                                                    I13
                                                </label>
                                            </div>
                                        }
                                        <div className="input-item">
                                            <Field
                                                type="radio"
                                                name="c_affichagec"
                                                id="filter3-2"
                                                className="sr-only"
                                                onChange={() => setFieldValue('c_affichagec', 2)}
                                                checked={values.c_affichagec === 2 ? true : false} />
                                            <label htmlFor="filter3-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                <Erreur />
                                                R13
                                            </label>
                                        </div>
                                        <div className="input-item">
                                            <Field
                                                type="radio"
                                                name="c_affichagec"
                                                id="filter3-3"
                                                className="sr-only"
                                                onChange={() => setFieldValue('c_affichagec', 0)}
                                                checked={(values.c_affichagec === 0 || values.c_affichagec === 4) ? true : false} />
                                            <label htmlFor="filter3-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                <SO />
                                                SO
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* 'Tarif D' */}
                            <div role="group" aria-labelledby="group4" className="affichage-item">
                                <div className="affichage-item-title-container">
                                    <div className="affichage-item-title">
                                        Tarif 'D'
                                    </div>
                                </div>
                                <div className="affichage-item-input-container">
                                    <div id="filter4" className="input-items-container">
                                        <div className="input-item">
                                            <Field
                                                type="radio"
                                                name="c_affichaged"
                                                id="filter4-1"
                                                className="sr-only"
                                                onChange={() => setFieldValue('c_affichaged', 1)}
                                                checked={values.c_affichaged === 1 ? true : false} />
                                            <label htmlFor="filter4-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                <OK />
                                                OK
                                            </label>
                                        </div>
                                        {props.interventionInfoGeneral.que_vp === false &&
                                            <div className="input-item">
                                                <Field
                                                    type="radio"
                                                    name="c_affichaged"
                                                    id="filter4-4"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_affichaged', 3)}
                                                    checked={values.c_affichaged === 3 ? true : false} />
                                                <label htmlFor="filter4-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Intervention />
                                                    I13
                                                </label>
                                            </div>
                                        }
                                        <div className="input-item">
                                            <Field
                                                type="radio"
                                                name="c_affichaged"
                                                id="filter4-2"
                                                className="sr-only"
                                                onChange={() => setFieldValue('c_affichaged', 2)}
                                                checked={values.c_affichaged === 2 ? true : false} />
                                            <label htmlFor="filter4-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                <Erreur />
                                                R13
                                            </label>
                                        </div>
                                        <div className="input-item">
                                            <Field
                                                type="radio"
                                                name="c_affichaged"
                                                id="filter4-3"
                                                className="sr-only"
                                                onChange={() => setFieldValue('c_affichaged', 0)}
                                                checked={(values.c_affichaged === 0 || values.c_affichaged === 4) ? true : false} />
                                            <label htmlFor="filter4-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                <SO />
                                                SO
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* 'Libre' */}
                            <div role="group" aria-labelledby="group5" className="affichage-item">
                                <div className="affichage-item-title-container">
                                    <div className="affichage-item-title">
                                        'Libre'
                                    </div>
                                </div>
                                <div className="affichage-item-input-container">
                                    <div id="filter5" className="input-items-container">
                                        <div className="input-item">
                                            <Field
                                                type="radio"
                                                name="c_affichagelibre"
                                                id="filter5-1"
                                                className="sr-only"
                                                onChange={() => setFieldValue('c_affichagelibre', 1)}
                                                checked={values.c_affichagelibre === 1 ? true : false} />
                                            <label htmlFor="filter5-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                <OK />
                                                OK
                                            </label>
                                        </div>
                                        {props.interventionInfoGeneral.que_vp === false &&
                                            <div className="input-item">
                                                <Field
                                                    type="radio"
                                                    name="c_affichagelibre"
                                                    id="filter5-4"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_affichagelibre', 3)}
                                                    checked={values.c_affichagelibre === 3 ? true : false} />
                                                <label htmlFor="filter5-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Intervention />
                                                    I13
                                                </label>
                                            </div>
                                        }
                                        <div className="input-item">
                                            <Field
                                                type="radio"
                                                name="c_affichagelibre"
                                                id="filter5-2"
                                                className="sr-only"
                                                onChange={() => setFieldValue('c_affichagelibre', 2)}
                                                checked={values.c_affichagelibre === 2 ? true : false} />
                                            <label htmlFor="filter5-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                <Erreur />
                                                R13
                                            </label>
                                        </div>
                                        <div className="input-item">
                                            <Field
                                                type="radio"
                                                name="c_affichagelibre"
                                                id="filter5-3"
                                                className="sr-only"
                                                onChange={() => setFieldValue('c_affichagelibre', 0)}
                                                checked={(values.c_affichagelibre === 0 || values.c_affichagelibre === 4) ? true : false} />
                                            <label htmlFor="filter5-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                <SO />
                                                SO
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Correspondance avec le lumineux */}
                            <div role="group" aria-labelledby="group6" className="affichage-item">
                                <div className="affichage-item-title-container">
                                    <div className="affichage-item-title">
                                        Correspondance avec le lumineux
                                    </div>
                                </div>
                                <div className="affichage-item-input-container">
                                    <div id="filter6" className="input-items-container">
                                        <div className="input-item">
                                            <Field
                                                type="radio"
                                                name="c_affichagelumineux"
                                                id="filter6-1"
                                                className="sr-only"
                                                onChange={() => setFieldValue('c_affichagelumineux', 1)}
                                                checked={values.c_affichagelumineux === 1 ? true : false} />
                                            <label htmlFor="filter6-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                <OK />
                                                OK
                                            </label>
                                        </div>
                                        {props.interventionInfoGeneral.que_vp === false &&
                                            <div className="input-item">
                                                <Field
                                                    type="radio"
                                                    name="c_affichagelumineux"
                                                    id="filter6-4"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_affichagelumineux', 3)}
                                                    checked={values.c_affichagelumineux === 3 ? true : false} />
                                                <label htmlFor="filter6-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Intervention />
                                                    I43
                                                </label>
                                            </div>
                                        }
                                        <div className="input-item">
                                            <Field
                                                type="radio"
                                                name="c_affichagelumineux"
                                                id="filter6-2"
                                                className="sr-only"
                                                onChange={() => setFieldValue('c_affichagelumineux', 2)}
                                                checked={values.c_affichagelumineux === 2 ? true : false} />
                                            <label htmlFor="filter6-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                <Erreur />
                                                R43
                                            </label>
                                        </div>
                                        <div className="input-item">
                                            <Field
                                                type="radio"
                                                name="c_affichagelumineux"
                                                id="filter6-3"
                                                className="sr-only"
                                                onChange={() => setFieldValue('c_affichagelumineux', 0)}
                                                checked={(values.c_affichagelumineux === 0 || values.c_affichagelumineux === 4) ? true : false} />
                                            <label htmlFor="filter6-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                <SO />
                                                SO
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Pendant la course : pas d'ambiguité sur le prix */}
                            <div role="group" aria-labelledby="group7" className="affichage-item">
                                <div className="affichage-item-title-container">
                                    <div className="affichage-item-title">
                                        Pendant la course : pas d'ambiguité sur le prix
                                    </div>
                                </div>
                                <div className="affichage-item-input-container">
                                    <div id="filter7" className="input-items-container">
                                        <div className="input-item">
                                            <Field
                                                type="radio"
                                                name="c_mention1"
                                                id="filter7-1"
                                                className="sr-only"
                                                onChange={() => setFieldValue('c_mention1', 1)}
                                                checked={values.c_mention1 === 1 ? true : false} />
                                            <label htmlFor="filter7-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                <OK />
                                                OK
                                            </label>
                                        </div>
                                        {props.interventionInfoGeneral.que_vp === false &&
                                            <div className="input-item">
                                                <Field
                                                    type="radio"
                                                    name="c_mention1"
                                                    id="filter7-4"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_mention1', 3)}
                                                    checked={values.c_mention1 === 3 ? true : false} />
                                                <label htmlFor="filter7-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Intervention />
                                                    I12
                                                </label>
                                            </div>
                                        }
                                        <div className="input-item">
                                            <Field
                                                type="radio"
                                                name="c_mention1"
                                                id="filter7-2"
                                                className="sr-only"
                                                onChange={() => setFieldValue('c_mention1', 2)}
                                                checked={values.c_mention1 === 2 ? true : false} />
                                            <label htmlFor="filter7-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                <Erreur />
                                                R12
                                            </label>
                                        </div>
                                        <div className="input-item">
                                            <Field
                                                type="radio"
                                                name="c_mention1"
                                                id="filter7-3"
                                                className="sr-only"
                                                onChange={() => setFieldValue('c_mention1', 0)}
                                                checked={(values.c_mention1 === 0 || values.c_mention1 === 4) ? true : false} />
                                            <label htmlFor="filter7-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                <SO />
                                                SO
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Mention 'Tarif' */}
                            <div role="group" aria-labelledby="group8" className="affichage-item">
                                <div className="affichage-item-title-container">
                                    <div className="affichage-item-title">
                                        Mention 'Tarif'
                                    </div>
                                </div>
                                <div className="affichage-item-input-container">
                                    <div id="filter8" className="input-items-container">
                                        <div className="input-item">
                                            <Field
                                                type="radio"
                                                name="c_mention3"
                                                id="filter8-1"
                                                className="sr-only"
                                                onChange={() => setFieldValue('c_mention3', 1)}
                                                checked={values.c_mention3 === 1 ? true : false} />
                                            <label htmlFor="filter8-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                <OK />
                                                OK
                                            </label>
                                        </div>
                                        {props.interventionInfoGeneral.que_vp === false &&
                                            <div className="input-item">
                                                <Field
                                                    type="radio"
                                                    name="c_mention3"
                                                    id="filter8-4"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_mention3', 3)}
                                                    checked={values.c_mention3 === 3 ? true : false} />
                                                <label htmlFor="filter8-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Intervention />
                                                    I12
                                                </label>
                                            </div>
                                        }
                                        <div className="input-item">
                                            <Field
                                                type="radio"
                                                name="c_mention3"
                                                id="filter8-2"
                                                className="sr-only"
                                                onChange={() => setFieldValue('c_mention3', 2)}
                                                checked={values.c_mention3 === 2 ? true : false} />
                                            <label htmlFor="filter8-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                <Erreur />
                                                R12
                                            </label>
                                        </div>
                                        <div className="input-item">
                                            <Field
                                                type="radio"
                                                name="c_mention3"
                                                id="filter8-3"
                                                className="sr-only"
                                                onChange={() => setFieldValue('c_mention3', 0)}
                                                checked={(values.c_mention3 === 0 || values.c_mention3 === 4) ? true : false} />
                                            <label htmlFor="filter8-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                <SO />
                                                SO
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* 'A Payer' ou quivalent fin de course sans supp. */}
                            <div role="group" aria-labelledby="group10" className="affichage-item">
                                <div className="affichage-item-title-container">
                                    <div className="affichage-item-title">
                                        'A Payer' ou équivalent
                                        <i className="fas fa-question-circle ctooltip">
                                            <span className="tooltiptext">
                                                'A Payer' ou quivalent en fin de course sans supp.
                                            </span>
                                        </i>
                                    </div>
                                </div>
                                <div className="affichage-item-input-container">
                                    <div id="filter10" className="input-items-container">
                                        <div className="input-item">
                                            <Field
                                                type="radio"
                                                name="c_mention2"
                                                id="filter10-1"
                                                className="sr-only"
                                                onChange={() => setFieldValue('c_mention2', 1)}
                                                checked={values.c_mention2 === 1 ? true : false} />
                                            <label htmlFor="filter10-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                <OK />
                                                OK
                                            </label>
                                        </div>
                                        {props.interventionInfoGeneral.que_vp === false &&
                                            <div className="input-item">
                                                <Field
                                                    type="radio"
                                                    name="c_mention2"
                                                    id="filter10-4"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_mention2', 3)}
                                                    checked={values.c_mention2 === 3 ? true : false} />
                                                <label htmlFor="filter10-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Intervention />
                                                    I12
                                                </label>
                                            </div>
                                        }
                                        <div className="input-item">
                                            <Field
                                                type="radio"
                                                name="c_mention2"
                                                id="filter10-2"
                                                className="sr-only"
                                                onChange={() => setFieldValue('c_mention2', 2)}
                                                checked={values.c_mention2 === 2 ? true : false} />
                                            <label htmlFor="filter10-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                <Erreur />
                                                R12
                                            </label>
                                        </div>
                                        <div className="input-item">
                                            <Field
                                                type="radio"
                                                name="c_mention2"
                                                id="filter10-3"
                                                className="sr-only"
                                                onChange={() => setFieldValue('c_mention2', 0)}
                                                checked={(values.c_mention2 === 0 || values.c_mention2 === 4) ? true : false} />
                                            <label htmlFor="filter10-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                <SO />
                                                SO
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <AutoSubmitAffichage passFormToParent={props.passFormToParent} />
                    </Form>
                )}
            </Formik>
        </div>
    )

}

const AutoSubmitAffichage = (props) => {

    // Grab values and submitForm from context
    const { values, submitForm } = useFormikContext()

    useEffect(() => {
        // Submit the form imperatively as an effect as soon as form values.token are 6 digits long
        if (values) {
            submitForm()
        }
    }, [])

    return null
}

export default Affichage