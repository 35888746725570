import React, { useState, useEffect, Fragment } from 'react'
import { formatDate, getArbreInterventionDetail, getArbreInterventionTachyDetail, isNullOrUndefined, isSiege } from '../../helpers/utils'
import Button from '@material-ui/core/Button'
import { dataService } from '../../helpers/dataService'
import { supervisionService } from './../../services/supervisionService'
import { toast, ToastContainer } from 'react-toastify'
import useConfirm from '../../hooks/useConfirm'
import { useAuth } from "./../../providers/authProvider"
import { useDataContext } from '../../providers/DataContextProvider'
import { Table } from '@material-ui/core'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const Intervention = (props) => {

    const auth = useAuth()
    const userRole = auth.userRole
    const [interventions, setinterventions] = useState(null)
    const [activite, setActivite] = useState(null)
    const { isConfirmed, isConfirmedDeblocage } = useConfirm()
    const { selectedIntervention, setSelectedIntervention, setInterventionInfoGeneral } = useDataContext()

    useEffect(() => {
        setinterventions(props.interventions.data)
        setActivite(props.interventions.activite)
        //console.log(props.interventions)
    }, [props.interventions])

    const handleClick = async (intervention) => {
        localStorage.removeItem("typeAppareils")
        // Même que celui sauvegardé dans le dataservice
        // Utiliser que celui à l'avenir
        setSelectedIntervention({ ...selectedIntervention, data: intervention })
        const dataLectureInterventionRequest = {
            intervention: {
                numero_adherent: intervention.numero_adherent,
                numero_client: intervention.numero_client,
                clef_stationnement: intervention.clef_stationnement,
                clef_lien: intervention.clef_lien,
                numero_intervention: intervention.numero_intervention
            },
            stock: {
                activite: 1,
            }
        }

        // Utilisateur adhérent
        // Procédure de vérification
        if (!isSiege(userRole)) {
            // Intervention pas encore effectuée (nouvelle intervention)
            if (isNullOrUndefined(intervention.date_effectuee)) {
                // Vérification de l'impartialité
                const confirmed = await isConfirmed("Impartialité (norme 17020:2012)", { "message": "Avez-vous avec ce client : Des liens familiaux/sentimentaux/amitié... ?" })
                // Oui, lien avec le client
                if (confirmed) {
                    toast.error("RISQUE IMPARTIALITE (norme 17020:2012) : \n Vous avez répondu oui à l'une des questions précédentes, contactez le siège pour savoir la marche à suivre. Vous ne pouvez pas faire cette intervention", { containerId: "App" })
                    return
                }
                // Non, pas de lien avec le client
                else {
                    // Vérification du stock de vignette
                    if (process.env.NODE_ENV === 'production') {
                        supervisionService.checkVignetteStockIntervention(dataLectureInterventionRequest)
                            .then(response => {
                                //console.log(response)
                                // Pas de vignette verte et rouge en stock
                                if (response.code !== 3) {
                                    toast.error(response.message, { containerId: 'App' })
                                    return
                                }
                                else {
                                    // Récupération des infos générales de l'intervention
                                    supervisionService.lectureIntervention(dataLectureInterventionRequest.intervention)
                                        .then(response => setInterventionInfoGeneral(response))
                                    // Démarrage de la saisie
                                    dataService.sendIntervention(intervention)
                                    localStorage.setItem("intervention", JSON.stringify(intervention)) // Pareil que dataService a modifier quand on va bien gérer le dataservice
                                    props.startSaisie(1)
                                    return
                                }
                            })
                    }
                    else {
                        // Récupération des infos générales de l'intervention
                        supervisionService.lectureIntervention(dataLectureInterventionRequest.intervention)
                            .then(response => setInterventionInfoGeneral(response))
                            .catch(error => console.log(error))
                        // Démarrage de la saisie
                        dataService.sendIntervention(intervention)
                        localStorage.setItem("intervention", JSON.stringify(intervention)) // Pareil que dataService a modifier quand on va bien gérer le dataservice
                        props.startSaisie(1)
                        return
                    }
                    /*process.env.NODE_ENV === 'production' && supervisionService.checkVignetteStockIntervention(dataLectureInterventionRequest)
                        .then(response => {
                            //console.log(response)
                            // Pas de vignette verte et rouge en stock
                            if (response.code !== 3) {
                                toast.error(response.message, { containerId: 'App' })
                                return
                            }
                            else {
                                // Récupération des infos générales de l'intervention
                                supervisionService.lectureIntervention(dataLectureInterventionRequest.intervention)
                                    .then(response => setInterventionInfoGeneral(response))
                                // Démarrage de la saisie
                                dataService.sendIntervention(intervention)
                                localStorage.setItem("intervention", JSON.stringify(intervention)) // Pareil que dataService a modifier quand on va bien gérer le dataservice
                                props.startSaisie()
                                return
                            }
                        })*/
                }
            }
            // Date existante (modification d'une intervention)
            else if (formatDate(intervention.date_effectuee) !== formatDate(Date.now() && intervention.date_effectuee > new Date("01/01/1900"))) {
                // Vérification de la poursuite modification
                if (process.env.NODE_ENV !== "development") {
                    const confirmEditIntervention = await isConfirmed("Modification Intervention", { message: "Vous souhaitez modifier les données, celle-ci a été déjà terminée depuis plus de 24h donc vous devez appeler le siège pour un code de déblocage. \n Souhaitez vous continuer ?" })
                    // Veut modifier
                    if (confirmEditIntervention) {
                        let confirmCodeDeblocage = null
                        let show_modal_deblocage = true

                        // Saisir code de déblocage
                        while (show_modal_deblocage) {
                            confirmCodeDeblocage = await isConfirmedDeblocage("Code de déblocage", { message: "Modification d'une intervention de plus de 24h", option: 2 })
                            if (confirmCodeDeblocage === "code_bon") {
                                // On commence la modification
                                show_modal_deblocage = false
                                // Récupération des infos générales de l'intervention
                                supervisionService.lectureIntervention(dataLectureInterventionRequest.intervention)
                                    .then(response => setInterventionInfoGeneral(response))
                                dataService.sendIntervention(intervention)
                                localStorage.setItem("intervention", JSON.stringify(intervention)) // Pareil que dataService a modifier quand on va bien gérer le dataservice
                                props.startSaisie(1)
                                return
                            }
                            else if (confirmCodeDeblocage === "code_incorrect") {
                                toast.error("Code de déblocage erroné.", { autoClose: 5000 })
                            }
                            else {
                                toast.error("Arrêt... Modification impossible.", { autoClose: 5000 })
                                show_modal_deblocage = false
                            }
                        }
                        return
                    }
                    return
                }
                else {
                    // Récupération des infos générales de l'intervention
                    supervisionService.lectureIntervention(dataLectureInterventionRequest.intervention)
                        .then(response => setInterventionInfoGeneral(response))
                    dataService.sendIntervention(intervention)
                    localStorage.setItem("intervention", JSON.stringify(intervention)) // Pareil que dataService a modifier quand on va bien gérer le dataservice
                    props.startSaisie(1)
                }
            }
            return
        }
        // Utilisateur siège utilise directement
        // et Adhérent peut faire intervention
        // Récupération des infos générales de l'intervention
        supervisionService.lectureIntervention(dataLectureInterventionRequest.intervention)
            .then(response => setInterventionInfoGeneral(response))
        // Suppression des anciennes données d'une intervention
        localStorage.removeItem("arretes")
        localStorage.removeItem("doc_arrete")
        localStorage.removeItem("doc_dpt")
        localStorage.removeItem("instruments_etalonnage")
        localStorage.removeItem("time")
        // Démarrage de la saisie
        supervisionService.getAllArreteNationalDpt(intervention.stationnement.clef_dpt)
            .then(response => localStorage.setItem("arretes", JSON.stringify(response)))
        dataService.sendIntervention(intervention)
        localStorage.setItem("intervention", JSON.stringify(intervention)) // Pareil que dataService a modifier quand on va bien gérer le dataservice
        props.startSaisie(1)
    }

    const handleClickTachy = async (intervention) => {
        setSelectedIntervention({ ...selectedIntervention, data: intervention })
        dataService.sendIntervention(intervention)
        localStorage.setItem("intervention", JSON.stringify(intervention)) // Pareil que dataService a modifier quand on va bien gérer le dataservice
        props.startSaisie(4)
    }

    return (
        <div className="section-clientint mt-3">
            <ToastContainer
                enableMultiContainer
                position="top-center"
                autoClose={false}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {activite && activite === 1 &&
                <div className="clientint-container">
                    <TableContainer className='web-table'>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Date</TableCell>
                                    <TableCell align="left">Client</TableCell>
                                    <TableCell align="left">Type</TableCell>
                                    <TableCell align="left">Stationnement</TableCell>
                                    <TableCell align="left">Taximètre</TableCell>
                                    <TableCell align="left">Technicien Prévu</TableCell>
                                    <TableCell align="center">Saisie</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {interventions && interventions.map((intervention, n) =>
                                    <TableRow
                                        key={n}
                                    >
                                        <TableCell component="th" scope="row">
                                            {intervention.date_effectuee ? <div className="t-bg-success">{new Date(intervention.date_effectuee).toLocaleString("fr-FR", { year: 'numeric', month: 'long', day: 'numeric' })}</div> : <div className="t-bg-danger">{new Date(intervention.date_intervention).toLocaleString("fr-FR", { year: 'numeric', month: 'long', day: 'numeric' })}</div>}
                                        </TableCell>
                                        <TableCell align="left" className='MultiTableCell-c-color'>{intervention.client.nom_client}</TableCell>
                                        <TableCell align="left">{getArbreInterventionDetail(intervention).text}</TableCell>
                                        <TableCell align="left">{intervention.stationnement.numero_stationnement + " " + intervention.stationnement.ville + " " + intervention.stationnement.cp}</TableCell>
                                        <TableCell align="left">{intervention.vehiculeTaximetre.taximetre.modeleTousAppareils.marque_nom_appareil + " " + intervention.vehiculeTaximetre.taximetre.modeleTousAppareils.modele_nom_appareil + " " + intervention.vehiculeTaximetre.taximetre.numero_serie_taximetre}</TableCell>
                                        {intervention.technicienPrevu &&
                                            <TableCell align="left">{intervention.technicienPrevu.prenom + " " + intervention.technicienPrevu.nom}</TableCell>
                                        }
                                        <TableCell align="center">
                                            <Button size="small" color="primary" onClick={e => handleClick(intervention)}>
                                                Commencer
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className="mobile-container">
                        {interventions && interventions.map((intervention, n) =>
                            <div className="intervention-item" key={n}>
                                <TableContainer className='mobile-table'>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <Fragment key={n}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" style={{ borderBottom: "none" }}>Intervention {n + 1} </TableCell>
                                                    <TableCell align="left" style={{ borderBottom: "none" }}>
                                                        <Button size="small" color="primary" onClick={e => handleClick(intervention)}>
                                                            Commencer
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Date d'intervention
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {intervention.date_effectuee ? <div className="t-bg-success">{new Date(intervention.date_effectuee).toLocaleString("fr-FR", { year: 'numeric', month: 'long', day: 'numeric' })}</div> : <div className="t-bg-danger">{new Date(intervention.date_intervention).toLocaleString("fr-FR", { year: 'numeric', month: 'long', day: 'numeric' })}</div>}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Nom client
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {intervention.client.nom_client}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Type
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {getArbreInterventionDetail(intervention).text}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Stationnement
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {intervention.stationnement.numero_stationnement + " " + intervention.stationnement.ville + " " + intervention.stationnement.cp}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Taximètre
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {intervention.vehiculeTaximetre.taximetre.modeleTousAppareils.marque_nom_appareil + " " + intervention.vehiculeTaximetre.taximetre.modeleTousAppareils.modele_nom_appareil + " " + intervention.vehiculeTaximetre.taximetre.numero_serie_taximetre}
                                                    </TableCell>
                                                </TableRow>
                                                {intervention.technicienPrevu &&
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" style={{ borderBottom: "none" }}>
                                                            Technicien prévu
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" style={{ borderBottom: "none" }}>
                                                            {intervention.technicienPrevu.prenom + " " + intervention.technicienPrevu.nom}
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                            </TableBody>
                                        </Fragment>
                                    </Table>
                                </TableContainer>
                            </div>
                        )}
                    </div>
                </div>
            }
            {activite && activite === 4 &&
                <div className="clientint-container">
                    <TableContainer className='web-table'>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Date</TableCell>
                                    <TableCell align="left">Client</TableCell>
                                    <TableCell align="left">Type</TableCell>
                                    <TableCell align="left">Véhicule</TableCell>
                                    <TableCell align="left">Tachy</TableCell>
                                    <TableCell align="left">Technicien Prévu</TableCell>
                                    <TableCell align="center">Saisie</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {interventions && interventions.map((intervention, n) =>
                                    <TableRow
                                        key={n}
                                    >
                                        <TableCell component="th" scope="row">
                                            {intervention.date_effectuee ? <div className="t-bg-success">{new Date(intervention.date_effectuee).toLocaleString("fr-FR", { year: 'numeric', month: 'long', day: 'numeric' })}</div> : <div className="t-bg-danger">{new Date(intervention.date_intervention).toLocaleString("fr-FR", { year: 'numeric', month: 'long', day: 'numeric' })}</div>}
                                        </TableCell>
                                        <TableCell align="left" className='MultiTableCell-c-color'>{intervention.client.nom_client}</TableCell>
                                        <TableCell align="left">{getArbreInterventionTachyDetail(intervention).text}</TableCell>
                                        <TableCell align="left">{intervention.vehicule.immatriculation_vehicule + " (" + (intervention.vehicule.modeleVehicule ? intervention.vehicule.modeleVehicule.marqueVehicule.marque_nom_vehicule : "") + " " + (intervention.vehicule.modeleVehicule ? intervention.vehicule.modeleVehicule.modele_nom_vehicule : "") + ")"}</TableCell>
                                        <TableCell align="left">{intervention.tachygraphe.modeleTousAppareils.marque_nom_appareil + " " + intervention.tachygraphe.modeleTousAppareils.modele_nom_appareil + " (" + intervention.tachygraphe.numero_serie_tachygraphe + ")"}</TableCell>
                                        {intervention.intervenant &&
                                            <TableCell align="left">{intervention.intervenant.prenom + " " + intervention.intervenant.nom}</TableCell>
                                        }
                                        <TableCell align="left">
                                            <Button size="small" color="primary" onClick={e => handleClickTachy(intervention)}>
                                                Commencer
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className="mobile-container">
                        {interventions && interventions.map((intervention, n) =>
                            <div className="intervention-item" key={n}>
                                <TableContainer className='mobile-table'>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <Fragment key={n}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" style={{ borderBottom: "none" }}>Intervention {n + 1} </TableCell>
                                                    <TableCell align="left" style={{ borderBottom: "none" }}>
                                                        <Button size="small" color="primary" onClick={e => handleClick(intervention)}>
                                                            Commencer
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Date d'intervention
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {intervention.date_effectuee ? <div className="t-bg-success">{new Date(intervention.date_effectuee).toLocaleString("fr-FR", { year: 'numeric', month: 'long', day: 'numeric' })}</div> : <div className="t-bg-danger">{new Date(intervention.date_intervention).toLocaleString("fr-FR", { year: 'numeric', month: 'long', day: 'numeric' })}</div>}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Nom client
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {intervention.client.nom_client}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Type
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {getArbreInterventionTachyDetail(intervention).text}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Véhicule
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {intervention.vehicule.immatriculation_vehicule + " (" + (intervention.vehicule.modeleVehicule ? intervention.vehicule.modeleVehicule.marqueVehicule.marque_nom_vehicule : "") + " " + (intervention.vehicule.modeleVehicule ? intervention.vehicule.modeleVehicule.modele_nom_vehicule : "") + ")"}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Tachy
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {intervention.tachygraphe.modeleTousAppareils.marque_nom_appareil + " " + intervention.tachygraphe.modeleTousAppareils.modele_nom_appareil + " (" + intervention.tachygraphe.numero_serie_tachygraphe + ")"}
                                                    </TableCell>
                                                </TableRow>
                                                {intervention.intervenant &&
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" style={{ borderBottom: "none" }}>
                                                            Technicien prévu
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" style={{ borderBottom: "none" }}>
                                                            {intervention.intervenant.prenom + " " + intervention.intervenant.nom}
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                            </TableBody>
                                        </Fragment>
                                    </Table>
                                </TableContainer>
                            </div>
                        )}
                    </div>
                </div>
            }
        </div>
    )

}

export default Intervention