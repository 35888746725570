import unlock from './../res/unlock.png'
/*import management from './../res/community-manager.png'
import team from './../res/team.png'*/
import supervision from './../res/supervised.png'
import soon from './../res/coming-soon.png'
import adherents from './../res/contact-information.png'
import documents from './../res/documents.png'
import mechanic from './../res/mechanic.png'
import loi from './../res/loi.png'
import rdv from './../assets/rendez-vous.png'
import maintenance from './../assets/maintenance.png'
import { ReactComponent as Accueil } from "./../res/page-accueil.svg"
import { ReactComponent as Deblocage } from "./../res/unlock.svg"
import { ReactComponent as MesAdherents } from "./../res/membership.svg"
import { ReactComponent as Intervention } from "./../res/wrench.svg"
import { ReactComponent as Supervision } from "./../res/hierarchical-structure.svg"
import { ReactComponent as Texte } from "./../res/file.svg"
import { ReactComponent as Docs } from "./../res/documents.svg"
import { ReactComponent as Soon } from "./../res/coming-soon.svg"
import { ReactComponent as PreIntervention } from "./../res/wrench.svg"


const userCase = [
    {
        name: 'Code de déblocage',
        link: 'deblocage',
        image: unlock
    },
    {
        name: 'Mes adhérents',
        image: adherents,
        link: 'adherents'
    },
    {
        name: 'Supervision',
        image: supervision,
        link: 'presupervision'
    },
    {
        name: 'Intervention',
        image: mechanic,
        link: 'intervention'
    },
    {
        name: 'Futur Intervention',
        image: maintenance,
        link: 'preintervention'
    },
    {
        name: 'Texte',
        image: loi,
        link: 'texte'
    },
    {
        name: 'Docs Qualités',
        image: documents,
        link: 'documents'
    },
    /*{
        name: 'Gestion des utilisateurs',
        link: 'users',
        image: management,
        disabled: 'disabled'
    },
    {
        name: 'Ajouter un compte',
        link: 'register',
        image: team,
        disabled: 'disabled'
    },*/
    {
        name: 'Coming soon...',
        image: soon,
        link: 'deblocage'
    }
]

const adherentCase = [
    {
        name: 'Intervention',
        image: mechanic,
        link: 'intervention'
    },
]

const adherentMenuContents = [
    {
        title: 'Accueil',
        url: '/',
        i: <Accueil />,
    },
    {
        title: 'Intervention',
        url: '/intervention',
        i: <Intervention />,
    },
]

const externeCase = [
    {
        name: 'Docs Qualités',
        image: documents,
        link: 'documents'
    },
]

const MenuContents = [
    {
        title: 'Accueil',
        url: '/',
        i: <Accueil />,
    },
    {
        title: 'Code de déblocage',
        url: '/deblocage',
        i: <Deblocage />,
    },
    {
        title: 'Mes adhérents',
        url: '/adherents',
        i: <MesAdherents />,
    },
    {
        title: 'Supervision',
        url: '/presupervision',
        disabled: '',
        i: <Supervision />,
    },
    {
        title: 'Intervention',
        url: '/intervention',
        i: <Intervention />,
    },
    {
        title: 'Futur Intervention',
        url: '/preintervention',
        i: <PreIntervention />,
    },
    {
        title: 'Texte',
        url: '/texte',
        i: <Texte />,
    },
    {
        title: 'Docs Qualités',
        url: '/documents',
        disabled: '',
        i: <Docs />,
    },
    /*{
        title: 'Gestion des utilisateurs',
        url: '/users',
        disabled: 'disabled',
        i: 'fa-user-cog',
    },
    {
        title: 'Ajouter un compte',
        url: '/register',
        disabled: 'disabled',
        i: 'fa-user-plus',
    },*/
    {
        title: 'Coming soon...',
        url: '/coming',
        i: <Soon />,
    },
]

const ExterneMenuContents = [
    {
        title: 'Accueil',
        url: '/',
        i: <Accueil />,
    },
    {
        title: 'Docs Qualités',
        url: '/documents',
        disabled: '',
        i: <Docs />,
    },
]

export {
    userCase,
    MenuContents,
    adherentCase,
    adherentMenuContents,
    externeCase,
    ExterneMenuContents
};
