import { Formik, Field, Form, ErrorMessage } from 'formik'
import { IoMdClose } from "react-icons/io"
import { Tabs } from 'antd'
import Switch from '@material-ui/core/Switch'
import { useLovelySwitchStyles } from '@mui-treasury/styles/switch/lovely'
import { ReactComponent as OK } from './../../../res/checked.svg'
import { ReactComponent as Erreur } from './../../../res/siren.svg'
import { ReactComponent as Alerte } from './../../../res/alerte.svg'
import { useRef, useState } from 'react'
import { useEffect } from 'react'
import { supervisionService } from '../../../services/supervisionService'
import { useAuth } from "./../../../providers/authProvider"
import UseAnimations from "react-useanimations"
import loading from "react-useanimations/lib/loading"
import { toast } from 'react-toastify'
const { TabPane } = Tabs


const ClientPopup = (props) => {

    const lovelyStyles = useLovelySwitchStyles()
    let auth = useAuth()
    const userGroup = auth.userGroup
    const [etatCivil, setEtatCivil] = useState()
    const [saving, setSaving] = useState(false)
    const formikRef = useRef()

    useEffect(() => {
        initEtatCivil()
    }, [])

    const initEtatCivil = () => {
        supervisionService.getEtatCivilClient(userGroup)
            .then(response => setEtatCivil(response))
    }

    const handleChangeCP = e => {
        let cp = e.target.value
        formikRef.current.setFieldValue("cp_client", cp)
        if (cp.length >= 5) {
            supervisionService.getCommuneByCP(cp)
                .then(response => formikRef.current.setFieldValue("ville_client", response))
                .catch(error => { console.log(error); formikRef.current.setFieldValue("ville_client", "") })
        }
        else {
            formikRef.current.setFieldValue("ville_client", "")
        }
    }

    const handleSaveClient = e => {
        if (formikRef.current) {
            formikRef.current.handleSubmit()
        }
    }

    const saveClient = (data) => {
        supervisionService.saveClient(data)
            .then(response => { toast.success(response.message); props.close(response) })
            .catch(error => toast.error(error, { containerId: 'App' }))
            .finally(() => setSaving(false))
    }

    return (
        <div className="popup-cover">
            <div className="popup-area">
                <div className="popup-header">
                    <h3 className="title">
                        {props.title}
                    </h3>
                    <div className="popup-close-button" onClick={props.close}>
                        <IoMdClose className="Comet-icon-close-menu" />
                    </div>
                </div>
                <div className="popup-body pt-0 popup-liaison-body">
                    <div className="form-saisie-container">
                        <Formik
                            innerRef={formikRef}
                            enableReinitialize
                            initialValues={{
                                nom_client: "",
                                actif_client: true,
                                numero_lien: false,
                                etat_civil_client: "",
                                adresse1_client: "",
                                adresse2_client: "",
                                reference_client: "",
                                cp_client: "",
                                ville_client: "",
                                tel_client: "",
                                fax_client: "",
                                email_client: "",
                                contact_client: "",
                                tel_contact: "",
                                fax_contact: "",
                                siret: "",
                                tva: "",
                                rcs_ville: "",
                                siren: "",
                                type_societe: "",
                                observations_client: "",
                                client_bloque: false,
                                trust_client: 0,
                            }}
                            validate={values => {
                                const errors = {}

                                if (values.nom_client.length < 4) {
                                    errors.nom_client = "Le nom du client doit être sur au moins 4 caractères."
                                }
                                if (values.nom_client === "") {
                                    errors.nom_client = "Vous devez identifier le nom du client."
                                }
                                if (values.cp_client.length !== 5) {
                                    errors.cp_client = "Le code postal est non valide."
                                }
                                if (values.ville_client === "") {
                                    errors.ville_client = "Vous devez identifier la ville."
                                }
                                if (values.etat_civil_client === "") {
                                    errors.etat_civil_client = "Vous devez identifier l'état civil."
                                }
                                if (values.tel_client === "") {
                                    errors.tel_client = "Vous devez identifier le téléphone du client."
                                }

                                return errors
                            }}
                            onSubmit={(values) => {
                                setSaving(true)
                                let data = values
                                data.numero_adherent = userGroup
                                saveClient(data)
                            }}
                        >
                            {({ values, setFieldValue }) => (
                                <Form>
                                    {/* Formulaire Confiance */}
                                    <div className="form-group">
                                        <div className="form-cell-input">
                                            <ul>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="trust_client"
                                                            id="trust_client-1"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('trust_client', 0)}
                                                            checked={values.trust_client === 0 ? true : false} />
                                                        <label htmlFor="trust_client-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <OK />
                                                            Confiance
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="trust_client"
                                                            id="trust_client-2"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('trust_client', 1)}
                                                            checked={values.trust_client === 1 ? true : false} />
                                                        <label htmlFor="trust_client-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <Alerte />
                                                            Surveiller
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="trust_client"
                                                            id="trust_client-3"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('trust_client', 2)}
                                                            checked={values.trust_client === 2 ? true : false} />
                                                        <label htmlFor="trust_client-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <Erreur />
                                                            Attention !
                                                        </label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Identité</label>
                                        {/* Nom et etat civil */}
                                        <div className="form-row">
                                            <div className="col">
                                                <Field className="form-control" type="text" name="nom_client" placeholder="Nom" />
                                                <ErrorMessage name="nom_client" component="div" className="text-danger" />
                                            </div>
                                            <div className="col">
                                                <Field type="text" className="form-control" name="etat_civil_client" list="etat_civil_clients" id="etat_civil_client" placeholder="Etat civil..." />
                                                <datalist id="etat_civil_clients">
                                                    {etatCivil && etatCivil.map((item, key) =>
                                                        <option key={key} value={item}>
                                                            {item}
                                                        </option>
                                                    )}
                                                </datalist>
                                                <ErrorMessage name="etat_civil_client" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* Adresse */}
                                    <div className="form-group">
                                        <label htmlFor="adresse1_client">Adresse</label>
                                        <Field className="form-control" type="text" name="adresse1_client" placeholder="Adresse 1" />
                                    </div>
                                    <div className="form-group">
                                        <Field className="form-control" type="text" name="adresse2_client" placeholder="Adresse 2" />
                                    </div>
                                    {/* Numéro interne */}
                                    <div className="form-group">
                                        <label htmlFor="reference_client">N° interne</label>
                                        <Field className="form-control" type="text" name="reference_client" placeholder="N° interne" />
                                    </div>
                                    {/* CP / Ville */}
                                    <div className="form-row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label htmlFor="cp_client">CP</label>
                                                <Field className="form-control" type="text" name="cp_client" placeholder="CP" onChange={handleChangeCP} />
                                                <ErrorMessage name="cp_client" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group">
                                                <label htmlFor="ville_client">Ville</label>
                                                <Field className="form-control" type="text" name="ville_client" placeholder="Ville" />
                                                <ErrorMessage name="ville_client" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <Tabs>
                                        {/* Coordonnées */}
                                        <TabPane tab="Coordonnées" key="1">
                                            {/* Téléphone / Fax */}
                                            <div className="form-row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label htmlFor="tel_client">Téléphone</label>
                                                        <Field className="form-control" type="text" name="tel_client" />
                                                        <ErrorMessage name="tel_client" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label htmlFor="fax_client">Fax</label>
                                                        <Field className="form-control" type="text" name="fax_client" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="email_client">Email</label>
                                                <Field className="form-control" type="text" name="email_client" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="contact_client">Contact</label>
                                                <Field className="form-control" type="text" name="contact_client" />
                                            </div>
                                            <div className="form-row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label htmlFor="tel_contact">Téléphone</label>
                                                        <Field className="form-control" type="text" name="tel_contact" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label htmlFor="fax_contact">Fax</label>
                                                        <Field className="form-control" type="text" name="fax_contact" />
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                        {/* Info Société */}
                                        <TabPane tab="Info Société" key="2">

                                            <div className="form-group">
                                                <label htmlFor="siret">SIRET</label>
                                                <Field className="form-control" type="text" name="siret" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="tva">N° TVA Intra</label>
                                                <Field className="form-control" type="text" name="tva" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="rcs_ville">RCS Ville</label>
                                                <Field className="form-control" type="text" name="rcs_ville" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="siren">SIREN</label>
                                                <Field className="form-control" type="text" name="siren" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="type_societe">Type Société</label>
                                                <Field className="form-control" type="text" name="type_societe" />
                                            </div>
                                        </TabPane>
                                        {/* Autres */}
                                        <TabPane tab="Autres" key="3">
                                            {/* Observations */}
                                            <div className="form-group">
                                                <label htmlFor="observations_client">Observations</label>
                                                <Field className="form-control" as="textarea" name="observations_client" />
                                            </div>
                                            <div className="form-group">
                                                <label className="options-label">Client Bloqué</label>
                                                <Switch
                                                    name="client_bloque"
                                                    classes={lovelyStyles}
                                                    checked={values.client_bloque}
                                                    value={values.client_bloque}
                                                //onChange={e => handleChangeBlinde(e, setFieldValue)}
                                                />
                                            </div>
                                        </TabPane>
                                    </Tabs>
                                    {/* Formulaire Actif */}
                                    {props.new &&
                                        <div className="form-group">
                                            <div className="form-cell-input">
                                                <ul>
                                                    <li>
                                                        <div className="input-radio-item">
                                                            <Field
                                                                type="radio"
                                                                name="actif_client"
                                                                id="actif_client-1"
                                                                className="sr-only"
                                                                onChange={() => setFieldValue('actif_client', true)}
                                                                checked={values.actif_client === true ? true : false} />
                                                            <label htmlFor="actif_client-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                <OK />
                                                                Actif
                                                            </label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="input-radio-item">
                                                            <Field
                                                                type="radio"
                                                                name="actif_client"
                                                                id="actif_client-2"
                                                                className="sr-only"
                                                                onChange={() => setFieldValue('actif_client', false)}
                                                                checked={values.actif_client === false ? true : false} />
                                                            <label htmlFor="actif_client-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                <Alerte />
                                                                Inactif
                                                            </label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    }
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div >
                <div className="popup-actions popup-actions-liaisons">
                    <div className="popup-action-open">
                        <button disabled={saving} type="button" className={"upload-file-btn" + (saving ? " disabled" : "")} onClick={handleSaveClient}>
                            <span> {saving ? <UseAnimations strokeColor={'var(--comet-white)'} animation={loading} size={24} style={{ padding: 20 }} wrapperStyle={{ marginLeft: 'auto', marginRight: 'auto' }} /> : "Enregistrer"}</span>
                        </button>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default ClientPopup