import { Formik, Field, Form, ErrorMessage } from 'formik'
import { IoMdClose } from "react-icons/io"
import { ReactComponent as OK } from './../../../res/checked.svg'
import { ReactComponent as Alerte } from './../../../res/alerte.svg'
import { ReactComponent as Adherent } from '../../../assets/tree-view/boss.svg'
import { ReactComponent as Clients } from '../../../assets/tree-view/clients.svg'
import { ReactComponent as Fabricant } from '../../../assets/tree-view/factory.svg'
import noImage from '../../../assets/tree-view/no-image.png'
import { useRef, useState } from 'react'
import { useEffect } from 'react'
import { supervisionService } from '../../../services/supervisionService'
import { useAuth } from "../../../providers/authProvider"
import UseAnimations from "react-useanimations"
import loading from "react-useanimations/lib/loading"
import { toast } from 'react-toastify'
import Checkbox from '@material-ui/core/Checkbox'

import * as React from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import { Tabs } from 'antd'
import { useAdherentContext } from '../../../providers/AdherentContextProvider'
const { TabPane } = Tabs

const TaximetrePopup = (props) => {
    let auth = useAuth()
    const userGroup = auth.userGroup
    const [saving, setSaving] = useState(false)
    const formikRef = useRef()
    const [selectedMarque, setSelectedMarque] = useState()
    const [marques, setMarques] = useState([])
    const [modeles, setModeles] = useState([])
    const [modele, setModele] = useState(null)
    const [fabricants, setFabricants] = useState([])
    const [selectedProprietaire, setSelectedProprietaire] = useState(0)
    const { adherent, setAdherent } = useAdherentContext()
    const [activeAfficheur, setActiveAfficheur] = useState(false)
    const clientData = (props.clients).filter(client => client.numero_client === parseInt((props.selected).split('_')[1]))[0]


    useEffect(() => {
        // Type 1 => Taximètre
        supervisionService.getMarqueAppareil(1)
            .then(response => { setMarques(response) })
            .catch(error => { toast.error(error, { containerId: 'App' }); console.log(error) })
    }, [])

    useEffect(() => {
        // Adhérent
        if (selectedProprietaire === 0) {
            formikRef.current.setFieldValue("proprio", adherent.nom_adherent)
            formikRef.current.setFieldValue("adresse1_proprio", adherent.adresse1_adherent ? adherent.adresse1_adherent : "")
            formikRef.current.setFieldValue("adresse2_proprio", adherent.adresse2_adherent ? adherent.adresse2_adherent : "")
            formikRef.current.setFieldValue("cp_proprio", adherent.cP_adherent)
            formikRef.current.setFieldValue("ville_proprio", adherent.ville_adherent)
        }
        else if (selectedProprietaire === 1) {
            formikRef.current.setFieldValue("recherche_proprio", clientData.nom_client)
            formikRef.current.setFieldValue("proprio", clientData.nom_client + " - " + clientData.cp_client + " " + clientData.ville_client)
            formikRef.current.setFieldValue("adresse1_proprio", clientData.adresse1_client ? clientData.adresse1_client : "")
            formikRef.current.setFieldValue("adresse2_proprio", clientData.adresse2_client ? clientData.adresse2_client : "")
            formikRef.current.setFieldValue("cp_proprio", clientData.cp_client)
            formikRef.current.setFieldValue("ville_proprio", clientData.ville_client)
        }
        else if (selectedProprietaire === 2) {
            formikRef.current.setFieldValue("recherche_proprio", "")
            formikRef.current.setFieldValue("proprio", "")
            formikRef.current.setFieldValue("adresse1_proprio", "")
            formikRef.current.setFieldValue("adresse2_proprio", "")
            formikRef.current.setFieldValue("cp_proprio", "")
            formikRef.current.setFieldValue("ville_proprio", "")
        }
    }, [selectedProprietaire])

    const handleChangeRechercheProprio = (e) => {
        const value = e.target.value
        formikRef.current.setFieldValue("recherche_proprio", value)
        // Recherche du propriétaire
        if (e.target.value && e.target.value.length >= 3) {
            let clients = props.clients
            let newClients = clients.filter(c => (c.nom_client.toLowerCase()).indexOf((e.target.value).toLowerCase()) !== -1)
            let client = newClients[0]
            formikRef.current.setFieldValue("proprio", client.nom_client + " - " + client.cp_client + " " + client.ville_client)
            formikRef.current.setFieldValue("cp_proprio", client.cp_client)
            formikRef.current.setFieldValue("adresse1_proprio", client.adresse1_client)
            formikRef.current.setFieldValue("adresse2_proprio", client.adresse2_client)
            formikRef.current.setFieldValue("ville_proprio", client.ville_client)
        }
    }

    const haveErrors = (data) => {
        let message = ""
        let error = true
        if (data.immatriculation_vehicule === "" && data.numero_serie.length < 17) {
            message = "Vous devez identifier l'immatriculation du véhicule."
        }
        else {
            error = false
        }

        return { error: error, message: message }
    }

    const handleChangeMarque = (e) => {
        const value = parseInt(e.target.value)
        setSelectedMarque(value)
        formikRef.current.setFieldValue(e.target.name, value)
        if (value !== -1) {
            supervisionService.getModeleAppareil(1, value)
                .then(response => setModeles(response))
                .catch(error => { toast.error(error, { containerId: 'App' }); formikRef.current.setFieldValue("modele_taximetre", -1) })
        }
        else {
            formikRef.current.setFieldValue("modele_taximetre", -1)
        }
    }

    const handleChangeModele = (e) => {
        const value = parseInt(e.target.value)
        formikRef.current.setFieldValue(e.target.name, value)
        if (value !== -1) {
            supervisionService.getModeleTousAppareils(selectedMarque, value)
                .then(response => {
                    console.log(response)
                    setModele(response);
                    formikRef.current.setFieldValue('numero_cet_initial', response.ref_dam)
                    if (response.afficheur && response.afficheur === true) {
                        setActiveAfficheur(true)
                    }
                    else {
                        setActiveAfficheur(false)
                    }
                })
                .catch(error => { toast.error(error, { containerId: 'App' }); formikRef.current.setFieldValue(e.target.name, -1) })
        }
        else {
            formikRef.current.setFieldValue(e.target.name, -1)
            formikRef.current.setFieldValue('numero_cet_initial', "")
        }
    }

    const handleChangeProprietaire = (e) => {
        const value = parseInt(e.target.value)
        const name = e.target.name
        formikRef.current.setFieldValue(name, value)
        setSelectedProprietaire(value)
        if (value === 2) {
            supervisionService.getAllFabricant()
                .then(response => { setFabricants(response) })
                .catch(error => { toast.error(error, { containerId: 'App' }); console.log(error) })
        }
    }

    const handleChangeFabricant = (e) => {
        const value = parseInt(e.target.value)
        formikRef.current.setFieldValue(e.target.name, value)
        if (value !== -1) {
            let fabricant = fabricants.filter(fabricant => fabricant.numero_ftaxi === value)[0]
            formikRef.current.setFieldValue("cp_proprio", fabricant.cp_ftaxi)
            formikRef.current.setFieldValue("adresse1_proprio", fabricant.adresse1_ftaxi)
            formikRef.current.setFieldValue("adresse2_proprio", fabricant.adresse2_ftaxi)
            formikRef.current.setFieldValue("ville_proprio", fabricant.ville_ftaxi)
        }
        else {
            formikRef.current.setFieldValue("cp_proprio", "")
            formikRef.current.setFieldValue("adresse1_proprio", "")
            formikRef.current.setFieldValue("adresse2_proprio", "")
            formikRef.current.setFieldValue("ville_proprio", "")
        }
    }

    const handleSaveTaximetre = (e) => {
        if (formikRef.current) {
            formikRef.current.handleSubmit()
        }
    }

    const saveTaximetre = async (request) => {
        const taximetre = await supervisionService.saveTaximetre(request)
        setSaving(false)
        props.close(taximetre)
    }

    return (
        <div className="popup-cover">
            <div className="popup-area">
                <div className="popup-header">
                    <h3 className="title">
                        {props.title}
                    </h3>
                    <div className="popup-close-button" onClick={e => props.close(null)}>
                        <IoMdClose className="Comet-icon-close-menu" />
                    </div>
                </div>
                <div className="popup-body pt-0 popup-liaison-body">
                    <div className="form-saisie-container">
                        <Formik
                            innerRef={formikRef}
                            enableReinitialize
                            initialValues={{
                                actif_taximetre: 0,
                                marque_taximetre: -1,
                                modele_taximetre: -1,
                                numero_serie_taximetre: "",
                                numero_serie_afficheur: "",
                                numero_cet_initial: "",
                                observations: "",
                                actif_proprio: 0,
                                recherche_proprio: "",
                                proprio: "",
                                adresse1_proprio: "",
                                adresse2_proprio: "",
                                cp_proprio: "",
                                ville_proprio: "",
                            }}
                            validate={values => {
                                const errors = {}

                                if (values.marque_vehicule === -1) {
                                    errors.marque_vehicule = "Vous devez renseigner la marque du taximètre."
                                }
                                if (values.modele_vehicule === -1) {
                                    errors.modele_vehicule = "Vous devez renseigner le modèle du taximètre."
                                }
                                if (values.numero_serie_taximetre === "") {
                                    errors.numero_serie_taximetre = "Vous devez renseigner le numéro de série du taximètre."
                                }
                                if (values.numero_serie_afficheur === "" && activeAfficheur === true) {
                                    errors.numero_serie_afficheur = "Ce taximètre a un afficheur et son numéro de série doit être renseigné."
                                }
                                if (values.proprio === -1) {
                                    errors.proprio = "Vous devez sélectionner le propriétaire."
                                }

                                return errors
                            }}
                            onSubmit={(values, errors) => {
                                setSaving(true)
                                let data = values
                                alert("Dans le cas d'un taximètre MID neuf vous devez vous assurer de la possession d'un Certificat de Conformité UE (à fournir avec le carnet)")
                                setSaving(false)
                                let selectedKeys = props.selected.split("_")
                                data.numero_adherent = userGroup
                                data.numero_client = parseInt(selectedKeys[1])
                                data.clef_stationnement = parseInt(selectedKeys[2])
                                data.numero_vehicule = parseInt(selectedKeys[3])
                                console.log("props.client", props.client)
                                data.clef_lien = props.client.subid
                                console.log("data", data)
                                /*data.numero_adherent = userGroup
                                data.immatriculation_vehicule = data.immatriculation_vehicule.replace(" ", "")
                                data.numero_serie = data.numero_serie.replace(" ", "")
                                data.marque_vehicule = parseInt(data.marque_vehicule)
                                data.modele_vehicule = parseInt(data.modele_vehicule)
                                setSaving(false)
                                if (haveErrors(data).error) {
                                    toast.error(haveErrors(data).message, { containerId: 'App' })
                                    setSaving(false)
                                    return
                                }
                                data.clef_stationnement = parseInt(props.selected.split("_")[2])*/
                                //saveVehicule(data)
                                saveTaximetre(data)
                            }}
                        >
                            {({ values, setFieldValue }) => (
                                <Form>
                                    <Tabs defaultActiveKey={1} tabPosition={'top'} style={{ height: 220, width: '100%' }} /*onChange={handleChangeTab}*/ >
                                        {/* Infos Taximètre */}
                                        <TabPane tab={<div className={"tab-title"}>Info Taxi</div>} key={1}>
                                            {/* Actif */}
                                            <div className="form-group">
                                                <div className="form-cell-input">
                                                    <ul>
                                                        <li>
                                                            <div className="input-radio-item">
                                                                <Field
                                                                    type="radio"
                                                                    name="actif_taximetre"
                                                                    id="actif_taximetre-1"
                                                                    className="sr-only"
                                                                    onChange={() => setFieldValue('actif_taximetre', 0)}
                                                                    checked={values.actif_taximetre === 0 ? true : false} />
                                                                <label htmlFor="actif_taximetre-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                    <OK />
                                                                    Actif
                                                                </label>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="input-radio-item">
                                                                <Field
                                                                    type="radio"
                                                                    name="actif_taximetre"
                                                                    id="actif_taximetre-2"
                                                                    className="sr-only"
                                                                    onChange={() => setFieldValue('actif_taximetre', 1)}
                                                                    checked={values.actif_taximetre === 1 ? true : false} />
                                                                <label htmlFor="actif_taximetre-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                    <Alerte />
                                                                    Inactif
                                                                </label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <Card sx={{ display: 'flex', marginBottom: '15px' }}>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '20px' }}>
                                                    {/* Constructeur */}
                                                    <div className="form-group">
                                                        <label>Constructeur</label>
                                                        <Field name="marque_taximetre" className="form-control" as="select" onChange={e => { handleChangeMarque(e) }}>
                                                            <option value="-1"></option>
                                                            {marques && marques.map((marque, index) =>
                                                                <option key={index} value={marque.marque_appareil}>{marque.marque_nom_appareil + " (" + marque.ville_taxi + ")"}</option>
                                                            )}
                                                        </Field>
                                                        <ErrorMessage name="marque_taximetre" component="div" className="text-danger" />
                                                    </div>
                                                    {/* Modèle */}
                                                    <div className="form-group">
                                                        <label htmlFor="modele_taximetre">Modèle</label>
                                                        <Field name="modele_taximetre" className="form-control" as="select" onChange={handleChangeModele}>
                                                            <option value="-1"></option>
                                                            {modeles && modeles.map((modele, index) =>
                                                                <option key={index} value={modele.modele_appareil}>{modele.marque_nom_appareil + " " + modele.modele_nom_appareil}</option>
                                                            )}
                                                        </Field>
                                                        <ErrorMessage name="modele_taximetre" component="div" className="text-danger" />
                                                    </div>
                                                </Box>
                                                <CardMedia
                                                    className='card-media'
                                                    component="img"
                                                    sx={{ width: 361, height: 219 }}
                                                    image={modele && modele.photo_1 ? `data:image/jpeg;base64,${modele.photo_1}` : noImage}
                                                    alt="Taximètre modèle"
                                                />
                                            </Card>
                                            {/* N° Série Centrale */}
                                            <div className="form-row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label htmlFor="numero_serie_taximetre">N° Série Centrale</label>
                                                        <Field className="form-control" type="text" name="numero_serie_taximetre" />
                                                        <ErrorMessage name="numero_serie_taximetre" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* N° Série Afficheur */}
                                            {activeAfficheur &&
                                                <div className="form-row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label htmlFor="numero_serie_afficheur">N° Série Afficheur</label>
                                                            <Field className="form-control" type="text" name="numero_serie_afficheur" />
                                                            <ErrorMessage name="numero_serie_afficheur" component="div" className="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {/* N° CET initial */}
                                            <div className="form-row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label htmlFor="numero_cet_initial">N° CET initial</label>
                                                        <Field className="form-control" type="text" name="numero_cet_initial" disabled value={values.numero_cet_initial} />
                                                        <ErrorMessage name="numero_cet_initial" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Observations */}
                                            <div className="form-row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label htmlFor="observations">Observations</label>
                                                        <Field className="form-control" as="textarea" name="observations" />
                                                        <ErrorMessage name="observations" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row m-m-0">
                                                <div className="form-cell-input checkbox-container">
                                                    <div className="form-input">
                                                        <Checkbox
                                                            name="location"
                                                            // value={values.c_r20}
                                                            // checked={values.c_r20}
                                                            inputProps={{
                                                                'aria-label': 'primary checkbox'
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            Location
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input checkbox-container">
                                                    <div className="form-input">
                                                        <Checkbox
                                                            name="relais"
                                                            // value={values.c_r20}
                                                            // checked={values.c_r20}
                                                            inputProps={{
                                                                'aria-label': 'primary checkbox'
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            Relais
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                        {/* Propriétaire */}
                                        <TabPane tab={<div className={"tab-title"}>Propriétaire</div>} key={2}>
                                            <div className="form-group">
                                                <div className="form-cell-input">
                                                    <ul>
                                                        <li>
                                                            <div className="input-radio-item">
                                                                <Field
                                                                    type="radio"
                                                                    name="actif_proprio"
                                                                    id="actif_proprio-1"
                                                                    className="sr-only"
                                                                    onChange={handleChangeProprietaire}
                                                                    value={0}
                                                                    checked={values.actif_proprio === 0 ? true : false} />
                                                                <label htmlFor="actif_proprio-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                    <Adherent />
                                                                    Adherent
                                                                </label>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="input-radio-item">
                                                                <Field
                                                                    type="radio"
                                                                    name="actif_proprio"
                                                                    id="actif_proprio-2"
                                                                    className="sr-only"
                                                                    onChange={handleChangeProprietaire}
                                                                    value={1}
                                                                    checked={values.actif_proprio === 1 ? true : false} />
                                                                <label htmlFor="actif_proprio-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                    <Clients />
                                                                    Client
                                                                </label>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="input-radio-item">
                                                                <Field
                                                                    type="radio"
                                                                    name="actif_proprio"
                                                                    id="actif_proprio-3"
                                                                    className="sr-only"
                                                                    value={2}
                                                                    onChange={handleChangeProprietaire}
                                                                    checked={values.actif_proprio === 2 ? true : false} />
                                                                <label htmlFor="actif_proprio-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                    <Fabricant />
                                                                    Fabricant
                                                                </label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            {/* Recherche */}
                                            {selectedProprietaire === 1 &&
                                                <div className="form-group">
                                                    <Field className="form-control" type="text" name="recherche_proprio" onChange={handleChangeRechercheProprio} />
                                                    <ErrorMessage name="recherche_proprio" component="div" className="text-danger" />
                                                </div>
                                            }
                                            {/* Proprio */}
                                            {selectedProprietaire === 2 ?
                                                <div className="form-group">
                                                    <Field name="proprio" className="form-control" as="select" onChange={handleChangeFabricant}>
                                                        <option value="-1"></option>
                                                        {fabricants && fabricants.map((fabricant, index) =>
                                                            <option key={index} value={fabricant.numero_ftaxi}>{fabricant.nom_ftaxi + " - " + fabricant.cp_ftaxi}</option>
                                                        )}
                                                    </Field>
                                                    <ErrorMessage name="proprio" component="div" className="text-danger" />
                                                </div> :
                                                <div className="form-group">
                                                    <Field name="proprio" className="form-control" type="text" disabled={selectedProprietaire !== 3 ? true : false} />
                                                    <ErrorMessage name="proprio" component="div" className="text-danger" />
                                                </div>
                                            }
                                            {/* Adresse */}
                                            <div className="form-group">
                                                {/* Adresse 1 */}
                                                <Field className="form-control" type="text" name="adresse1_proprio" disabled />
                                            </div>
                                            <div className="form-group">
                                                {/* Adresse 2 */}
                                                <Field className="form-control" type="text" name="adresse2_proprio" disabled />
                                            </div>
                                            {/* CP / Ville */}
                                            <div className="form-row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <Field className="form-control" type="text" name="cp_proprio" disabled />
                                                        <ErrorMessage name="cp_proprio" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                        <Field className="form-control" type="text" name="ville_proprio" disabled />
                                                        <ErrorMessage name="ville_proprio" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                    </Tabs>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div >
                <div className="popup-actions popup-actions-liaisons">
                    <div className="popup-action-open">
                        <button disabled={saving} type="button" className={"upload-file-btn" + (saving ? " disabled" : "")} onClick={handleSaveTaximetre}>
                            <span> {saving ? <UseAnimations strokeColor={'var(--comet-white)'} animation={loading} size={24} style={{ padding: 20 }} wrapperStyle={{ marginLeft: 'auto', marginRight: 'auto' }} /> : "Enregistrer"}</span>
                        </button>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default TaximetrePopup