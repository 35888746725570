import React, { useRef } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { IoMdClose } from "react-icons/io"

const SignaturePopup = (props) => {

    let sigCanvas = useRef({})

    const clearSigCanvas = () => {
        sigCanvas.clear()
    }

    const saveSigCanvas = () => {
        props.setFieldValue(props.inputName, (sigCanvas.getTrimmedCanvas().toDataURL("image/png")).split(",")[1])
        props.close()
    }

    return (
        <div className="popup-cover">
            <div className="popup-area">
                <div className="popup-header">
                    <h3 className="title">
                        {props.title}
                    </h3>
                    <div className="popup-close-button" onClick={props.close}>
                        <IoMdClose className="Comet-icon-close-menu" />
                    </div>
                </div>
                {props.subtitle &&
                    <div className='p-3'>{props.subtitle}</div>
                }
                <div className="popup-body">
                    <div className="signature-container">
                        <SignatureCanvas
                            ref={(ref) => { sigCanvas = ref }}
                            penColor='black'
                            canvasProps={{ width: 300, height: 300, className: 'sigCanvas' }}
                        />
                    </div>
                    <div className="sig-button-container mt-4">
                        <button type="button" className="btn btn-danger" onClick={clearSigCanvas}>
                            Effacer
                        </button>
                        <button type="button" className="btn cbtn-success ml-4" onClick={saveSigCanvas}>
                            Enregistrer
                        </button>
                    </div>
                </div>
            </div>
        </div >
    )

}

export default SignaturePopup